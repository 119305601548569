import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { graphql } from "gatsby";
import Img from 'gatsby-image';
import React from "react";
import { Helmet } from "react-helmet";
import slug from 'slug';
import Logo from '../assets/images/keyring-planet-logo-white.svg';
import BottomNav from "../components/BottomNav.js";
import Footer from "../components/Footer.js";
import Hero from "../components/Hero.js";

export default function Listings(props) {
  const { currentPage, numPages } = props.pageContext;
  const prevPage = currentPage - 1 === 1 ? "/" : (currentPage - 1).toString();
  const nextPage = (currentPage + 1).toString();

  const theme = useTheme();

  const useStyles = makeStyles(theme => ({
    button: {
      margin: theme.spacing(1)
    },
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8)
    },
    card: {
      height: "100%",
      display: "flex",
      flexDirection: "column"
    },
    cardMedia: {
      paddingTop: "50%"
    },
    cardContent: {
      flexGrow: 1
    },
    logo: {
      marginRight: theme.spacing(6),
      height: "10em",
      width: "10em"
    }
  }));

  const classes = useStyles();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Keyring Planet</title>
      </Helmet>
      <CssBaseline />
      <AppBar position="relative">
        <Toolbar>
          <a href="/" title="Keyring Planet">
            <Logo className={classes.logo}/>
          </a>
          <Typography component="h1" variant="h4" color="inherit" noWrap>
            Keyring Planet
          </Typography>
        </Toolbar>
      </AppBar>
      <main>
        {/*  */}
        <Hero />
        {/*  */}
        <Container className={classes.cardGrid} maxWidth="md">
          <Grid container spacing={4}>
            {props.data.allEtsyListing.nodes.map(card => (
              <Grid item key={card.id} xs={12} sm={6} md={4}>
                <Card className={classes.card}>
                  <a href={slug(card.title)} title={card.title}>
                    {card.childrenEtsyListingImage.length && card.childrenEtsyListingImage[0].childrenFile[0] && card.childrenEtsyListingImage[0].childrenFile[0].childImageSharp && <Img key={card.title} fluid={card.childrenEtsyListingImage[0].childrenFile[0].childImageSharp.fluid} title={card.title} />}
                  </a>
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {card.title}
                    </Typography>
                    <Typography>
                      &pound;{card.price}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button href={slug(card.title)} size="small" color="primary">
                      View
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
      {/*  */}
      <BottomNav
        numPages={numPages}
        prevPage={prevPage}
        nextPage={nextPage}
        currentPage={currentPage}
        theme={theme}
      />
      {/*  */}
      <Footer />
    </>
  );
}

export const pageQuery = graphql`
  query allEtsyQuery($skip: Int!, $limit: Int!) {
    allEtsyListing(
      sort: { fields: featured_rank, order: ASC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        id
        currency_code
        title
        listing_id
        price
        url
        childrenEtsyListingImage {
          rank
          childrenFile {
            childImageSharp {
              fluid (maxWidth: 2000, maxHeight: 1700, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;