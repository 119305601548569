import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";

export default function Hero() {
  const useStyles = makeStyles(theme => ({
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6)
    },
    heroButtons: {
      marginTop: theme.spacing(4)
    }
  }));

  const classes = useStyles();

  return (
    <div className={classes.heroContent}>
      <Container maxWidth="sm">
        <Typography
          component="h2"
          variant="h2"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          Personalise your keys
        </Typography>
        <Typography
          component="p">
            Hi, <span role="img" aria-label="Hand waving">👋</span>
        </Typography>
        <Typography
          component="p">
            Thanks for visiting Keyring Planet! All items on this page are handmade in Dorset by myself. I take pride in making high quality keyrings for people.
        </Typography>
        <Typography
          component="p">
            Personalised your keys!!! <span role="img" aria-label="Key">🔑</span> <span role="img" aria-label="Tag">🏷️</span> <span role="img" aria-label="Present">🎁</span>.
        </Typography>
        <Typography
          component="p">
            Enjoy the store and hope you find something you like!
        </Typography>
        <Typography 
          component="h3"
          variant="h3"
          align="center">
            Keyrings / Keychains
        </Typography>
      </Container>
    </div>
  );
}
